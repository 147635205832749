"use client";
import { Breadcrumbs, Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import navigationEnglish from "../../../public/content/navigationEnglish.json";

interface NavigationProps {
    backgroundColor?: string;
    textColor?: string;
    textColorHover?: string;
    arrowSrc?: string;
    arrowHoverSrc?: string;
}
export default function Navigation({
    backgroundColor,
    textColor,
    textColorHover,
    arrowSrc,
    arrowHoverSrc,
}: NavigationProps) {
    const [opened, { open, close }] = useDisclosure(false);
    const [subnavName, setSubnavName] = useState("");
    const [subsubnavName, setSubsubnavName] = useState("");
    const [rowThreeName, setRowThreeName] = useState("");
    const [query, setQuery] = useState("");
    const router = useRouter();
    useLockBodyScroll();
    const pathname = usePathname();

    return (
        <>
            <div
                className={twMerge(
                    "h-screen w-full py-0 px-0 z-50 sticky",
                    backgroundColor,
                )}
            >
                <div className=" lg:flex px-3 justify-center px-auto py-10 justify-items-center ">
                    <div className="lg:w-360 w-full  lg:h-200 lg:px-20 lg:pt-15  lg:pb-50 flex-col justify-start items-center gap-20  lg:inline-flex">
                        <div className="self-stretch w-full p-3  justify-start items-center gap-1 inline-flex">
                            {/* <div className="w-6 h-6  md:hidden justify-center items-center flex">
                                <img
                                    className="w-6 h-6 relative flex-col justify-start items-start flex"
                                    src={`${
                                        backgroundColor === "bg-darkGray"
                                            ? "/Icons/Search.svg"
                                            : "/Icons/SearchBlack.svg"
                                    } `}
                                />
                            </div>
                            <div
                                className={`${textColor} md:hidden grow shrink basis-0 text-base font-normal  leading-normal`}
                            >
                                Search
                            </div> */}

                            <div className="flex justify-center lg:hidden">
                                <div className="w-full px-3 h-12 justify-between items-center space-x-2 inline-flex">
                                    <button
                                        type="button"
                                        className=" rounded-sm justify-start items-start gap-1 flex"
                                        onClick={open}
                                    >
                                        <div
                                            className={`text-center  ${textColor} text-opacity-70 text-sm font-normal  uppercase leading-normal`}
                                        >
                                            {pathname.startsWith("/de") ? "De" : "En"}
                                        </div>
                                        <Image
                                            className="w-6 h-6 relative"
                                            src="/Icons/Header/dropdownWhite.svg"
                                            alt=""
                                            width={20}
                                            height={20}
                                        />
                                    </button>
                                    <Link href={"https://app.rico-brunner.com/"}>
                                        <div className=" rounded-lg justify-center items-center gap-1 flex">
                                            <div
                                                className={twMerge(
                                                    "text-center grid my-auto text-opacity-70 text-base font-normal leading-normal",
                                                    textColor,
                                                )}
                                            >
                                                Login
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex lg:hidden my-5 space-x-2">
                            <div>
                                <Breadcrumbs>
                                    {pathname
                                        .slice(1)
                                        .replace("de", "home")
                                        .replace("en", "home")
                                        .split("/")
                                        .map((item) => {
                                            return (
                                                <p
                                                    key={item}
                                                    className={twMerge(
                                                        "capitalize",
                                                        textColor,
                                                    )}
                                                >
                                                    {item.length > 15
                                                        ? `
                                            ${item.slice(0, 14).replaceAll("-", " ")}..
                                            `
                                                        : `
                                            ${item.replaceAll("-", " ")}
                                            `}
                                                </p>
                                            );
                                        })}
                                </Breadcrumbs>
                            </div>
                        </div>

                        <div className=" w-full px-auto justify-start items-start lg:justify-between lg:inline-flex">
                            <div>
                                <div
                                    className={twMerge(
                                        "lg:w-60 w-full flex-col justify-center items-center inline-flex ",
                                    )}
                                >
                                    {navigationEnglish.navigationEn.map((item) => {
                                        let Component: string | typeof Link = "a";
                                        let hrefProps = { href: "#" };
                                        if (item.url) {
                                            // Let the typescript compiler know the type of the component is now typeof Link
                                            Component = Link;
                                            hrefProps = { href: item.url };
                                        }
                                        return (
                                            <Component
                                                className="self-stretch p-3 border-b cursor-pointer border-neutral-700 justify-start items-center gap-1 inline-flex"
                                                key={item.name}
                                                {...hrefProps}
                                                onMouseEnter={() => {
                                                    // If we are on iphone, do nothing
                                                    if (
                                                        navigator.userAgent.match(
                                                            /(iPod|iPhone|iPad)/,
                                                            // /(iPod|iPhone|iPad|Android|webOS|BlackBerry|Windows Phone)/,
                                                        )
                                                    ) {
                                                        return;
                                                    }

                                                    setSubnavName(item.name);
                                                }}
                                            >
                                                <div
                                                    className={twMerge(
                                                        `grow shrink basis-0 text-base font-normal leading-normal hover:${textColorHover}`,
                                                        subnavName === item.name
                                                            ? textColorHover
                                                            : textColor,
                                                    )}
                                                >
                                                    {item.name}
                                                    {item.name.includes("Book now") && (
                                                        <div className="text-black p-1 mt-2 text-left bg-cyan rounded-md">
                                                            Subscription:{" "}
                                                            <span className="font-bold">
                                                                $69/Month
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* 
                                                 {subsubnav.name.includes(
                                                                            "Jetz",
                                                                        ) && (
                                                                            <div className="text-black p-1 mt-2 text-left bg-cyan rounded-md">
                                                                                Abo-Preis:
                                                                                CHF 68.-
                                                                                / EURO
                                                                                70.-
                                                                            </div>
                                                                        )}
                                                */}
                                            </Component>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer position="bottom" onClose={close} opened={opened} size={"xs"}>
                    <div className="block lg:hidden w-full  z-50 relative bg-white rounded-tl-lg rounded-tr-lg">
                        <div className="w-12 h-1 left-41 top-2 absolute bottom-0 bg-stone-300 rounded" />
                        <div className="w-full p-3 left-0 top-7 absolute border-b border-zinc-300 justify-start items-center gap-1 inline-flex">
                            <a
                                href="https://rico-brunner.com/de"
                                className="grow shrink basis-0 text-neutral-800 text-base font-normal leading-normal"
                            >
                                DE
                            </a>
                        </div>
                        <div className="w-full p-3 left-0 top-19 absolute justify-start items-center gap-1 inline-flex">
                            <a
                                href="/"
                                className="grow shrink basis-0 text-neutral-800 text-base font-normal leading-normal"
                            >
                                ENG
                            </a>
                        </div>
                    </div>
                </Drawer>
            </div>
        </>
    );
}
