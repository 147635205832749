"use client";
import { Accordion } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import footerEnglish from "../../../public/content/footerEnglish.json";

interface FooterProps {
    backgroundColor?: string;
    textColorTitle?: string;
    textColorLink?: string;
    googleLogo?: string;
    facebookLogo?: string;
    appleLogo?: string;
    buttonColor?: string;
    buttonTextColor?: string;
    srcIconAccordion?: string;
    windowsLogo?: string;
}
export default function Footer({
    backgroundColor = "bg-darkGray",
    textColorTitle = "text-white",
    textColorLink = "text-stone-300",
    googleLogo = "/Icons/Footer/GoogleWhite.svg",
    facebookLogo = "/Icons/Footer/FacebookWhite.svg",
    appleLogo = "/Icons/Footer/AppleWhite.svg",
    buttonColor = "bg-white",
    buttonTextColor = "text-black",
    srcIconAccordion = "/Icons/Header/dropdownWhite.svg",
    windowsLogo = "/Icons/Footer/windowsWhite.svg",
}: FooterProps) {
    const year = new Date().getFullYear();
    const router = useRouter();
    const [openModal, setOpenModal] = useState(false);
    return (
        <div className={`${backgroundColor}`}>
            <div className="lg:container mx-auto">
                <div className="w-full h-full px-5 lg:px-20 pt-20 pb-10  flex-col justify-start items-start gap-10 inline-flex ">
                    <div className="w-full px-6  justify-start items-start gap-8  lg:inline-flex hidden">
                        {footerEnglish.footerNavEn.map((item) => (
                            <div
                                className="w-60 flex-col justify-start items-start gap-2 inline-flex"
                                key={item.title}
                            >
                                <div
                                    className={twMerge(
                                        "text-2xl font-bold leading-loose",
                                        textColorTitle,
                                    )}
                                >
                                    {item.title}
                                </div>
                                {item.links.map((link) => (
                                    <div
                                        key={link.name}
                                        className={twMerge(
                                            "text-xl1 font-normal leading-8.5",
                                            textColorLink,
                                        )}
                                    >
                                        {link.url ? (
                                            <>
                                                <Link href={link.url}>{link.name}</Link>
                                            </>
                                        ) : (
                                            <>
                                                <div className="flex gap-2">
                                                    {link.name === "Switzerland" && (
                                                        <Image
                                                            alt=""
                                                            width={20}
                                                            height={20}
                                                            src="/Icons/Footer/Switzerland.svg"
                                                        />
                                                    )}{" "}
                                                    {link.name}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                                {item.title === "General" && (
                                    <div className="text-black p-1 mt-2 text-left bg-cyan rounded-md">
                                        Subscription:
                                        <span className="font-bold">$69/Month</span>
                                    </div>
                                )}
                                {item.title === "Legal" && (
                                    <button
                                        type="button"
                                        className={twMerge(
                                            "text-left text-xl1 font-normal leading-8.5",
                                            textColorLink,
                                        )}
                                        data-cc="show-preferencesModal"
                                    >
                                        Manage cookie settings
                                    </button>
                                )}
                            </div>
                        ))}
                        <div className="lg:w-[450px] lg:pt-2 flex-col justify-start items-start gap-6 inline-flex">
                            <div className="self-stretch h-43 flex-col justify-start items-start gap-2 flex">
                                <div
                                    className={twMerge(
                                        "self-stretch text-xl  lg:text-2xl  font-bold leading-loose",
                                        textColorTitle,
                                    )}
                                >
                                    Support
                                </div>
                                <div
                                    className={twMerge(
                                        "self-stretch text-xl lg:text-xl1 font-normal leading-7 lg:leading-8.5",
                                        textColorLink,
                                    )}
                                >
                                    You don`t have to live with emotional injuries and
                                    blockages. Remote treatment can help.
                                </div>
                                <button
                                    onClick={() => {
                                        router.push("/book-now");
                                    }}
                                    type="button"
                                    className={twMerge(
                                        "w-44 h-10 px-3 py-2 rounded-lg justify-start items-center gap-1 inline-flex",
                                        buttonColor,
                                    )}
                                >
                                    <div className="w-6 h-6 justify-center items-center flex">
                                        <Image
                                            alt=""
                                            height={20}
                                            width={20}
                                            className="w-6 h-6 relative flex-col justify-start items-start flex"
                                            src="/Icons/favorite.svg"
                                        />
                                    </div>
                                    <div
                                        className={twMerge(
                                            "text-center text-opacity-70 text-sm font-normal leading-normal",
                                            buttonTextColor,
                                        )}
                                    >
                                        Looking for help?
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="grow shrink mt-6 lg:pt-2 lg:mt-0 basis-0 flex-col justify-start items-start gap-5 inline-flex">
                            <div className="h-33 pb-1 flex-col justify-start items-start gap-2 hidden md:flex lg:flex">
                                <div
                                    className={twMerge(
                                        "self-stretch lg:text-2xl font-bold leading-loose",
                                        textColorTitle,
                                    )}
                                >
                                    Payment methods
                                </div>
                                <div className="flex space-x-2 lg:block lg:space-y-2 lg:space-x-0">
                                    <Image
                                        alt=""
                                        src={"/images/cardsEng.png"}
                                        width={300}
                                        height={50}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:hidden inline-flex w-full bg-darkGray -ml-4">
                        <Accordion
                            className={twMerge("w-full", backgroundColor)}
                            variant="filled"
                            chevron={
                                <Image
                                    alt=""
                                    height={20}
                                    width={20}
                                    src={srcIconAccordion}
                                    className="h-6 w-6"
                                />
                            }
                            chevronPosition="right"
                        >
                            {footerEnglish.footerNavEn.slice(1, 3).map((item) => (
                                <Accordion.Item value={item.title} key={item.title}>
                                    <Accordion.Control className={`${backgroundColor}`}>
                                        <p
                                            className={twMerge(
                                                "text-xl font-semibold leading-loose",
                                                textColorTitle,
                                            )}
                                        >
                                            {item.title}
                                        </p>
                                    </Accordion.Control>
                                    <Accordion.Panel className={`${backgroundColor}`}>
                                        {item.links.map((link) => (
                                            <div key={link.name}>
                                                {link.url ? (
                                                    <Link
                                                        href={link.url}
                                                        key={link.name}
                                                    >
                                                        <div
                                                            className={twMerge(
                                                                "text-lg font-normal leading-8.5",
                                                                textColorTitle,
                                                            )}
                                                        >
                                                            {link.name}
                                                        </div>
                                                    </Link>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={twMerge(
                                                                "text-lg font-normal leading-8.5",
                                                                textColorTitle,
                                                            )}
                                                        >
                                                            {link.name}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                        {item.title === "General" && (
                                            <div className="text-black p-1 mt-2 text-left bg-cyan rounded-md">
                                                Subscription:
                                                <span className="font-bold">
                                                    $69/Month
                                                </span>
                                            </div>
                                        )}
                                        {item.title === "Legal" && (
                                             <button
                                             type="button"
                                             className={twMerge(
                                                 "text-left text-xl1 font-normal leading-8.5",
                                                 textColorLink,
                                             )}
                                             data-cc="show-preferencesModal"
                                         >
                                             Manage cookie settings
                                         </button>
                                        )}
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                    <div className="lg:w-[450px] lg:hidden lg:pt-2 flex-col justify-start items-start gap-6 inline-flex">
                        <div className="self-stretch h-43 flex-col justify-start items-start gap-2 flex">
                            <div
                                className={twMerge(
                                    "self-stretch text-xl  lg:text-2xl  font-bold leading-loose",
                                    textColorTitle,
                                )}
                            >
                                Support
                            </div>
                            <div
                                className={twMerge(
                                    "self-stretch text-xl lg:text-xl1 font-normal leading-7 lg:leading-8.5",
                                    textColorLink,
                                )}
                            >
                                You don`t have to live with emotional injuries and
                                blockages. Remote treatment can help.
                            </div>
                            <button
                                onClick={() => {
                                    router.push("/book-now");
                                }}
                                type="button"
                                className={twMerge(
                                    "w-44 h-10 px-3 py-2 rounded-lg justify-start items-center gap-1 inline-flex",
                                    buttonColor,
                                )}
                            >
                                <div className="w-6 h-6 justify-center items-center flex">
                                    <Image
                                        alt=""
                                        height={20}
                                        width={20}
                                        className="w-6 h-6 relative flex-col justify-start items-start flex"
                                        src="/Icons/favorite.svg"
                                    />
                                </div>
                                <div
                                    className={twMerge(
                                        "text-center text-opacity-70 text-sm font-normal leading-normal",
                                        buttonTextColor,
                                    )}
                                >
                                    Looking for help?
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className=" pb-1 flex-col justify-start items-start gap-2 flex md:hidden lg:hidden ">
                        <div className="self-stretch text-white text-xl font-bold leading-loose">
                            Payment methods
                        </div>
                        <div className="flex space-x-2 lg:block lg:space-y-2 lg:space-x-0">
                            <Image
                                alt=""
                                height={50}
                                width={100}
                                src="/images/cardsEng.png"
                            />
                        </div>
                    </div>
                    <div className="lg:hidden block">
                        <div className="w-60 flex-col justify-start items-start gap-2 inline-flex">
                            <div
                                className={twMerge(
                                    "text-xl font-bold leading-loose",
                                    textColorTitle,
                                )}
                            >
                                {footerEnglish.footerNavEn[0].title}
                            </div>
                            {footerEnglish.footerNavEn[0].links.map((link) => (
                                <div
                                    key={link.name}
                                    className={twMerge(
                                        "text-lg font-normal leading-7",
                                        textColorTitle,
                                    )}
                                >
                                    <div className="flex gap-2">
                                        {link.name === "Switzerland" && (
                                            <Image
                                                alt=""
                                                height={20}
                                                width={20}
                                                src="/Icons/Footer/Switzerland.svg"
                                            />
                                        )}{" "}
                                        {link.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex space-x-2 lg:px-4 -mt-4">
                        <Image alt="" height={30} width={30} src={googleLogo} />
                        <Image alt="" height={30} width={30} src={facebookLogo} />
                        <Image alt="" height={30} width={30} src={appleLogo} />
                        <Image alt="" height={30} width={30} src={windowsLogo} />
                    </div>
                    <div className="self-stretch justify-start lg:px-4 items-center gap-1 inline-flex">
                        <div
                            className={twMerge(
                                "grow shrink basis-0  text-base font-normal leading-tight",
                                textColorLink,
                            )}
                        >
                            © 1998-{year} - Rico Brunner
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
